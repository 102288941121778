import React, { useEffect } from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./Contact.css";

const Contact = () => {
  const transitionVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.7 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const { ref, inView } = useInView();

  const control = useAnimation();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      id="contact"
      ref={ref}
      animate={control}
      initial="hidden"
      variants={transitionVariant}
    >
      <h1 id="contactTitle">Get in touch!</h1>
      <p id="contactMessage">
        If you'd like to discuss potential employment opportunites, freelance
        work, or just want to say "Hi", please reach out via{" "}
        <a
          id="liLink"
          href="https://www.linkedin.com/in/josh-richardson1/"
          target="_blank"
          rel="noreferrer noopener"
        >
          LinkedIn
        </a>{" "}
        or use the form below. I'd love to hear from you!
      </p>
      <div id="contactIconsContainer">
        <a
          href="https://www.linkedin.com/in/josh-richardson1/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            className="contactIcons"
            src={require("../../Images/Li2.png")}
            alt="LinkedIn"
            width="60"
            height="60"
          />
        </a>
        <a
          href="https://github.com/joshrichardson1"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            className="contactIcons"
            src={require("../../Images/githublogo.png")}
            alt="Github"
            width="50"
            height="50"
          />
        </a>
        <a
          href="https://docs.google.com/document/d/1wxgph7pfnMQumWiFM6i_7RM46cDCG1EZOMQowpGHnEo/edit?usp=sharing"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            className="contactIcons"
            src={require("../../Images/cv-icon.png")}
            alt="Resume"
            width="50"
            height="50"
          />
        </a>
        <a
          href="mailto:josh1rich1@gmail.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            className="contactIcons"
            src={require("../../Images/maillogo.png")}
            alt="Gmail"
            width="50"
            height="50"
          />
        </a>
      </div>
      <ContactForm />
    </motion.div>
  );
};

export default Contact;
