import React from "react";
import { Box } from "@mui/material/";
import "./Main.css";
import AppNav from "../components/Nav/AppNav";
import Sidebar from "../components/Sidebar/Sidebar";
import ScrollTop from "../components/ScrollTop/ScrollTop";
import ProjectSlider from "../components/ProjectSlider/ProjectSlider";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Intro from "./Intro/Intro";

const Main = () => {

  return (
    <Box id="pageContainer">
      <div id="mainContainer">
        <AppNav />
        <Sidebar />
        <Intro />
      </div>
      <div className="divider"></div>
      <div id='mainAboutContainer'>
        <About />
      </div>
      <div className="divider"></div>
      <div id="projects">
        <ProjectSlider />
      </div>
      <div className="divider"></div>
      <Contact />
      <ScrollTop />
      <footer id="footer">
        <p id="footerText">&copy; 2022 Josh Richardson</p>
      </footer>
    </Box>
  );
};

export default Main;
