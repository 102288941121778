import { React } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SideNav from "../SideNav/SideNav";
import "./Nav.css";

const AppNav = () => {
  return (
    <div id="navContainer">
      <SideNav />
      <Navbar collapseOnSelect bg="transparent" variant="dark" expand="lg">
        <Container id="navBarContainer">
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="custom-navbar-toggler"
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link className="landing-navbar-links" href="#intro">
                &#60;Home &#47;&#62;
              </Nav.Link>
              <Nav.Link className="landing-navbar-links" href="#about">
                &#60;About &#47;&#62;
              </Nav.Link>
              <Nav.Link className="landing-navbar-links" href="#projects">
                &#60;Projects &#47;&#62;
              </Nav.Link>
              <Nav.Link className="landing-navbar-links" href="#contact">
                &#60;Contact &#47;&#62;
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default AppNav;
