import React, { useEffect } from "react";
import { Grid } from "@mui/material/";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FrontEnd, BackEnd, Tools } from "../../components/TechStack/TechStack";
import HeadshotLean from "../../Images/hsLean.jpg";

import "./About.css";

export default function About() {
  const isMobile = window.innerWidth <= 500;

  const transitionVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.6 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const transitionVariant2 = {
    visible: { opacity: 1, scale: 1, transition: { duration: 1.0 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const transitionVariant3 = {
    visible: { opacity: 1, scale: 1, transition: { duration: 1.2 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const { ref, inView } = useInView();

  const control = useAnimation();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  if (!isMobile) {
    return (
      <Grid container id="about">
        <Grid item xs={12} md={6} id="aboutDesc" className="desktop">
          <motion.div
            id="aboutTitle"
            ref={ref}
            animate={control}
            initial="hidden"
            variants={transitionVariant}
          >
            <h2>About Me</h2>
          </motion.div>
          <motion.div
            id="aboutPar"
            ref={ref}
            animate={control}
            initial="hidden"
            variants={transitionVariant}
          >
            Hello, World! Welcome to my portfolio. My name is Josh Richardson and I am a
            Full-Stack Software Developer with a passion for solving problems
            through code. Whether it's what the user sees or what's going on
            behind the scenes, I love to build applications that are both
            beautiful and functional. I'm a life-long learner who is always
            looking to improve my skills and learn new technologies. When I'm
            not coding, you can catch me at a concert, strumming a guitar,
            camping with my family, or relentlessly beating my wife and kids in
            a board game. No free passes here!
          </motion.div>
          <motion.div
            id="techStackTitle"
            ref={ref}
            animate={control}
            initial="hidden"
            variants={transitionVariant2}
          >
            <h2>Technologies I use:</h2>
            <p>(Click to view details)</p>
          </motion.div>
          <Grid container id="techStackContainer" spacing={5}>
            <Grid item xs={12} md={4} id="frontendContainer">
              <motion.div
                className="techTitles"
                ref={ref}
                animate={control}
                initial="hidden"
                variants={transitionVariant2}
              >
                <FrontEnd />
              </motion.div>
            </Grid>
            <Grid item xs={12} md={4} id="backendContainer">
              <motion.div
                className="techTitles"
                ref={ref}
                animate={control}
                initial="hidden"
                variants={transitionVariant3}
              >
                <BackEnd />
              </motion.div>
            </Grid>
            <Grid item xs={12} md={4} id="toolsContainer">
              <motion.div
                className="techTitles"
                ref={ref}
                animate={control}
                initial="hidden"
                variants={transitionVariant2}
              >
                <Tools />
              </motion.div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} id="aboutImageContainer" className="desktop">
          <motion.img
            id="aboutImg"
            src={HeadshotLean}
            alt="Josh Richardson"
            // height="700px"
            ref={ref}
            animate={control}
            initial="hidden"
            variants={transitionVariant}
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container id="about">
      <Grid item xs={12} md={6} id="aboutDesc" className="desktop">
        <div id="aboutTitle">
          <h2>About Me</h2>
        </div>
        <div id="aboutPar">
          Hello, World! Welcome to my portfolio. My name is Josh Richardson and
          I am a Full-Stack Software Developer with a passion for solving
          problems through code. Whether it's what the user sees or what's going
          on behind the scenes, I love to build applications that are both
          beautiful and functional. I'm a life-long learner who is always
          looking to improve my skills and learn new technologies. When I'm not
          coding, you can catch me at a concert, strumming a guitar, camping
          with my family, or relentlessly beating my wife and kids in a board
          game. No free passes here!
        </div>
        <div id="techStackTitle">
          <h2>Technologies I use:</h2>
          <p id="clickMessage">(Click to view details)</p>
        </div>
        <Grid container id="techStackContainer" spacing={5}>
          <Grid item xs={12} md={4} id="frontendContainer">
            <div className="techTitles">
              <FrontEnd />
            </div>
          </Grid>
          <Grid item xs={12} md={4} id="backendContainer">
            <div className="techTitles">
              <BackEnd />
            </div>
          </Grid>
          <Grid item xs={12} md={4} id="toolsContainer">
            <div className="techTitles">
              <Tools />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
