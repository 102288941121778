import React from "react";
import "./Sidebar.css";

const Sidebar = () => {
  return (
    <div className="sidebarContainer">
      <a
        href="https://www.linkedin.com/in/josh-richardson1/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          id="linkedInIcon"
          className="sideIcons"
          src={require("../../Images/Li2.png")}
          alt="LinkedIn"
          width="60"
          height="60"
        />
      </a>
      <a
        href="https://github.com/joshrichardson1"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          className="sideIcons"
          src={require("../../Images/githublogo.png")}
          alt="Github"
          width="50"
          height="50"
        />
      </a>
      <a
        href="https://docs.google.com/document/d/1wxgph7pfnMQumWiFM6i_7RM46cDCG1EZOMQowpGHnEo/edit?usp=sharing"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          className="sideIcons"
          src={require("../../Images/cv-icon.png")}
          alt="Resume"
          width="50"
          height="50"
        />
      </a>
      <a
        href="mailto:josh1rich1@gmail.com"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          className="sideIcons"
          src={require("../../Images/maillogo.png")}
          alt="Gmail"
          width="50"
          height="50"
        />
      </a>
    </div>
  );
};

export default Sidebar;
