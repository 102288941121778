import React from 'react'
import Typewriter from "typewriter-effect";
import headshot from '../../Images/smallHeadshot.jpeg';
import './Intro.css'
import '../Main.css'

const Intro = () => {
  return (
    <div id="introContainer">
      <div id="intro">
        <h1 id="hi">Hi!</h1>
        <h2 id="name">
          I'm <span id="nameSpan">Josh Richardson,</span>
        </h2>
        <h3 id="title">
          <Typewriter
            options={{
              strings: [
                "a Full-Stack Software Developer.",
                "a life-long learner.",
                "a problem solver.",
                "a team player.",
                "a creative thinker.",
                "the next developer on your team?",
                "you made it this far?",
              ],
              autoStart: true,
              loop: true,
              delay: 25,
              deleteSpeed: 5,
            }}
          />
        </h3>
      </div>
      <div id='mobileImageContainer'>
        <img id='mobileImage' src={headshot} alt='headshot' />
        </div>
    </div>
  );
};

export default Intro;
