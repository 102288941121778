import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import fe from "../../Images/reicon1-White.png";
import be from "../../Images/beicon-White.png";
import tools from "../../Images/toolicon-White.png";
import "./TechStack.css";

const FrontEnd = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <div
        className="cardFront cardStyle"
        onClick={() => setIsFlipped(!isFlipped)}
      >
        <div>Front-end</div>
        <img src={fe} alt="fe" className="cardImages" />
      </div>
      <div
        className="cardBack cardStyle"
        onClick={() => setIsFlipped(!isFlipped)}
      >
        <div>HTML</div>
        <div>CSS</div>
        <div>JavaScript</div>
        <div>React</div>
        <div>Bootstrap</div>
        <div>MaterialUI</div>
        <div>Jest</div>
      </div>
    </ReactCardFlip>
  );
};

const BackEnd = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <div
        className="cardFront cardStyle"
        onClick={() => setIsFlipped(!isFlipped)}
      >
        <div>Back-end</div>
        <img src={be} alt="be" className="cardImages" />
      </div>
      <div
        className="cardBack cardStyle"
        onClick={() => setIsFlipped(!isFlipped)}
      >
        <div>Python</div>
        <div>Django</div>
        <div>Node.js</div>
        <div>PostgreSQL</div>
        <div>RESTful API's</div>
        <div>Pytest</div>
      </div>
    </ReactCardFlip>
  );
};

const Tools = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <div
        className="cardFront cardStyle"
        onClick={() => setIsFlipped(!isFlipped)}
      >
        <div>Tools</div>
        <img src={tools} alt="tools" className="cardImages" />
      </div>
      <div className="cardBack cardStyle"
        onClick={() => setIsFlipped(!isFlipped)}>
        <div>Git</div>
        <div>Heroku</div>
        <div>Netlify</div>
        <div>Postman</div>
        <div>Postico</div>
        <div>Canva</div>
        <div>VSCode</div>
      </div>
    </ReactCardFlip>
  );
};

export { FrontEnd, BackEnd, Tools };
