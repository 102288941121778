import React, { useState } from "react";
import { GoThreeBars } from "react-icons/go";
import "./SideNav.css";

const SideNav = () => {
  const [width, setWidth] = useState("0px");

  const openSideNav = () => {
    setWidth("250px");
  };

  const closeSideNav = () => {
    setWidth("0px");
  };

  return (
    <div id="sideNavContainer" className="sideNavContainer">
      <div id="mySideNav" className="sideNav" style={{ width: width }}>
        <a
          href="javascript:void(0)"
          className="closebtn"
          onClick={closeSideNav}
        >
          &times;
        </a>
        <a href="#about" onClick={closeSideNav}>
          &#60;About &#47;&#62;
        </a>
        <a href="#projects" onClick={closeSideNav}>
          &#60;Projects &#47;&#62;
        </a>
        <a href="#contact" onClick={closeSideNav}>
          &#60;Contact &#47;&#62;
        </a>
      </div>
      <span onClick={openSideNav} id="menuIcon">
        <GoThreeBars />
      </span>
    </div>
  );
};

export default SideNav;
