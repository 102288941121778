import React from "react";
import { Carousel } from "react-bootstrap";
import { Grid } from "@mui/material";
import VV from "../../Images/VinylVaultSS-removebg-preview.png";
import PnP from "../../Images/PnP-removebg-preview.png";
import Portfolio from "../../Images/Portfolio-removebg-preview.png";
import "./ProjectSlider.css";

const ProjectSlider = () => {
  return (
    <div id="projectSlideContainer">
      <div id="projectsTitle">
        <h2>Projects</h2>
        <p id="swipe">(Swipe through)</p>
      </div>
      <Carousel id="mainCarousel" interval={null}>
        <Carousel.Item id="slides">
          <Grid container>
            <Grid item xs={12} md={6} id="imageContainer">
              <div id="vvImage" className="projectImages">
                <img
                  src={VV}
                  alt="Vinyl Vault Preview"
                  className="projectImages"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} id="textContainer">
              <div id="vvText">
                <h3 className="slideTitles">Vinyl Vault</h3>
                <p className="projParagraph">
                  Vinyl Vault is a full-stack application built for music lovers
                  (like myself)! It allows users to create an account, search
                  for albums, and add them to their personal collection. Users
                  can also view and purchase tickets to any upcoming live shows
                  for artists in their collection.
                </p>
                <div id="projLinkContainer">
                  <div>
                    <a
                      href="https://www.vinyl-vault.net/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="projLink"
                    >
                      Live
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://github.com/joshrichardson1/VinylVault"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="projLink"
                    >
                      Repo
                    </a>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div id="languageIconContainer">
            <i
              id="projLangIcon"
              className="devicon-javascript-plain colored projLangIcon"
            ></i>
            <span className="please">
              <i className="devicon-react-original-wordmark colored projLangIcon"></i>
            </span>
            <span className="please">
              <i className="devicon-python-plain-wordmark colored projLangIcon"></i>
            </span>
            <i className="devicon-django-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-postgresql-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-html5-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-css3-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-bootstrap-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-materialui-plain colored projLangIcon"></i>
            <i className="devicon-heroku-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-github-original-wordmark projLangIcon git"></i>
          </div>
        </Carousel.Item>
        <Carousel.Item id="slides">
          <Grid container>
            <Grid item xs={12} md={6} id="imageContainer">
              <div id="vvImage">
                <img
                  src={PnP}
                  alt="Vinyl Vault Preview"
                  className="projectImages"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} id="textContainer">
              <div id="vvText">
                <h3 className="slideTitles">Parents-n-Parks</h3>
                <p className="projParagraph">
                  Parents-n-Parks is a full-stack social-media platform aimed to
                  connect parents with kids similar in age and share interests
                  similar to their own. User's can create a profile, add
                  friends, browse local family-friendly events, and plan meetups
                  with other users.
                </p>
                <div id="projLinkContainer">
                  <div>
                    <a
                      href="https://www.parentsnparks.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="projLink"
                    >
                      Live
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://github.com/joshrichardson1/parents-n-parks-backend"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="projLink"
                    >
                      Repo
                    </a>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div id="languageIconContainer">
            <i className="devicon-javascript-plain colored projLangIcon"></i>
            <i className="devicon-react-original-wordmark colored projLangIcon"></i>
            <i className="devicon-python-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-django-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-postgresql-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-amazonwebservices-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-html5-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-css3-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-bootstrap-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-materialui-plain colored projLangIcon"></i>
            <i className="devicon-heroku-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-github-original-wordmark projLangIcon git"></i>
          </div>
        </Carousel.Item>
        <Carousel.Item id="slides">
          <Grid container>
            <Grid item xs={12} md={6} id="imageContainer">
              <div id="vvImage">
                <img
                  src={Portfolio}
                  alt="Portfolio"
                  className="projectImages"
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} id="textContainer">
              <div id="vvText">
                <h3 className="slideTitles">This Portfolio</h3>
                <p className="projParagraph">
                  Well I couldn't list my projects without including this
                  portfolio, right? I've gone through a few iterations of this
                  portfolio, but I think I've finally settled on a design that
                  I'm happy with. I'm always open to suggestions, so feel free
                  to reach out!
                </p>
              </div>
            </Grid>
          </Grid>
          <div id="languageIconContainer">
            <i
              id="projLangIcon"
              className="devicon-javascript-plain colored projLangIcon"
            ></i>
            <i className="devicon-react-original-wordmark colored projLangIcon"></i>
            <i className="devicon-html5-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-css3-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-bootstrap-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-materialui-plain colored projLangIcon"></i>
            <i className="devicon-heroku-plain-wordmark colored projLangIcon"></i>
            <i className="devicon-github-original-wordmark projLangIcon git"></i>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default ProjectSlider;
